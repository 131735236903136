<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
    </li>
  </ul>

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">

    <li class="nav-item dropdown user-menu">
      <a href="#" class="nav-link dropdown-toggle pr-0" data-toggle="dropdown" style="top: -6px;">
        <img style="height: 35px;" src="../../assets/images/user-avatar.png" class="user-img-sm img-circle elevation-2">
      </a>
      <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <!-- User image -->
        <li class="user-header bg-default">
          <img src="../../assets/images/user-avatar.png" class="user-img-lg img-circle elevation-2">
          <p>
            <small></small>
          </p>
        </li>
        <!-- Menu Footer-->
        <li class="user-footer">
          <a (click)="open('editdados')" class="btn btn-default btn-flat">Editar Dados</a>
          <a (click)="logout()" class="btn btn-danger btn-flat float-right">Logout</a>
        </li>
      </ul>
    </li>

  </ul>
</nav>
<!-- /.navbar -->

<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-dark-warning elevation-4">
  <!-- Brand Logo -->
  <a (click)="goNavigate('home')" class="brand-link" style="background: #343a40 !important">
    <img src="../../assets/images/logo-skytech.png" style="opacity: .9; width: 200px; margin-left: 6px;">
  </a>

  <!-- Sidebar -->
  <div class="sidebar">

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column nav-flat nav-legacy" data-widget="treeview" role="menu"
        data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class
                with font-awesome or any other icon font library -->
        <li class="nav-item has-treeview" style="padding: 20px 0 0 0;">
          <a href="#" class="nav-link">
            <img style="height: 35px;" src="../../assets/images/user-avatar.png"
              class="user-img-sm img-circle elevation-2">
            <p style="margin-left: 8px;">
              {{user.nome}}
              <i class="fas fa-angle-left right"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a (click)="logout()" class="nav-link">
                <i class="far fa-circle nav-icon"></i>
                <p>Logout</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-header">MENU</li>
        <li class="nav-item">
          <a (click)="goNavigate('home')" class="nav-link" [ngClass]="validaRota('/home')">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>
              Dashboard
            </p>
          </a>
        </li>
        <li class="nav-item">
          <a (click)="goNavigate('soli-pedido')" class="nav-link" [ngClass]="validaRota('/soli-pedido')">
            <i class="nav-icon fas fa-address-card"></i>
            <p>
              Solicitação Pedido
            </p>
          </a>
        </li>

      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>