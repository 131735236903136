<app-sidebar></app-sidebar>
<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <section class="content-header mr-1">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12 text-center">
          <button class="btn bg-gradient-success mr-1" style="color: white;" (click)="open(contentPedido, null);pedidoDataRegistro();"><i
              class="fas fa-plus-circle mr-1"></i>Novo Pedido</button>
          <button class="btn bg-gradient-lightblue" style="color: white;"><i class="fas fa-print"></i>  Imprimir</button>
        </div>
      </div>
    </div><!-- /.container-fluid -->
  </section>

  <!-- Main content -->
  <section class="content ml-2 mr-2">

    <!-- Default box -->
    <div class="card">
      <div class="card-header" style="border-bottom: none;">
        <h3 class="card-title"><i class="fas fa-address-card mr-3"></i> Pedidos </h3>
        <div class="card-tools">
          <input [(ngModel)]="searchValue" (input)="search()" style="width: 80vh;" type="text" class="form-control"
            placeholder="Pesquisa">
          <a href="javascript:void();"><i class="icon-magnifier"></i></a>
        </div>
      </div>
      <div class="card-body p-0">

        <div id="printTable">

          <div class="d-none d-print-inline-block p-3">
            <img src="../../all/dist/img/sky_globo.png" style="max-width: 33px; margin-right: 8px;">
            <img src="../../all/dist/img/sky_logo.png" style="max-width: 100px;">
          </div>

          <table class="table table-striped table-hover text-center" id="myTable">
            <thead class="bg-gradient-light">
              <tr>
                <th class="d-print-none">Ações</th>
                <th>Protocolo</th>
                <th>Status</th>
                <th>Data Registro</th>
                <th>Data Conclusão</th>
                <th>Expresa</th>
                <th>Valor Mercadoria</th>
                <th>Produto Predominante</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of filteredList | paginate: { itemsPerPage: 10, currentPage: paginaAtual }">

              <tr>
                <td>
                  <button class="btn bg-gradient-lightblue btn-sm" (click)="open(content,item,true)"><i
                      class="fas fa-eye"></i></button>
                  <!-- <button class="btn bg-gradient-warning btn-sm" data-toggle="modal" data-target="#editar"><i class="fas fa-cog fa-spin"></i></button> -->
                </td>
                <td>{{item.protocolo}}</td>
                <td>
                  <div>
                    <div style="background: #afafaf;" class="fundo-stts" *ngIf="item.situacao === 1">
                      <p class="stts">PENDENTE</p>
                    </div>
                    <div style="background: orange;" class="fundo-stts" *ngIf="item.situacao === 2">
                      <p class="stts">AGUARDANDO</p>
                    </div>
                    <div style="background: #00e600" class="fundo-stts" *ngIf="item.situacao === 3">
                      <p class="stts">CONCLUIDO</p>
                    </div>
                  </div>
                </td>
                <td>{{item.dataregistro | date:'dd/MM/yyyy HH:mm:ss':'+0000'}}</td>
                <td>{{item.dataconclusao | date:'dd/MM/yyyy HH:mm:ss':'+0000'}}</td>
                <td>{{item.expressa}}</td>
                <td>{{item.vlrmercadoria}}</td>
                <td>{{item.produtopred}}</td>
              </tr>

            </tbody>
          </table>
          <div class="pagination-controls">
            <pagination-controls (pageChange)="paginaAtual = $event" previousLabel="Anterior" nextLabel="Próximo">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card -->

  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->

<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark">
  <!-- Control sidebar content goes here -->
</aside>
<!-- /.control-sidebar -->

<!-- Modal Detalhes-->
<ng-template #content>
  <section class="content ml-2 mr-2 mt-2">

    <div class="card">
      <div class="modal-header">
        <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>Detalhes Item</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
          <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
        </div>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="card-body p-3">
        <table class="table table-striped table-hover text-center" id="myTable">
          <thead class="bg-gradient-light">
            <tr>
              <th>Protocolo</th>
              <th>Data Registro</th>
              <th>Data Conclusão</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{dados.protocolo}}</td>
              <td>{{dados.dataregistro | date:'dd/MM/yyyy HH:mm:ss':'+0000' }}</td>
              <td>{{dados.dataconclusao | date:'dd/MM/yyyy HH:mm:ss':'+0000'}}</td>
            </tr>

          </tbody>
          <thead class="bg-gradient-light">
            <tr>
              <th>Expressa</th>
              <th>Valor Mercadoria</th>
              <th>Produto Predominante</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{dados.expressa}}</td>
              <td>{{dados.vlrmercadoria}}</td>
              <td>{{dados.produtopred}}</td>

            </tr>

          </tbody>
        </table>
      </div>

      <div class="card-body p-3">
        <button class="btn bg-gradient-success m-2" (click)="openPropMot(contentPropMot)"><i
            class="fas fa-plus-circle mr-1"></i>Adicionar Prop / Mot</button>
        <button style="float: right;" class="btn bg-gradient-success m-2" (click)="openVeiculo(contentVeiculo)"><i
            class="fas fa-plus-circle mr-1"></i>Adicionar Tração / Reboque</button>
        <table class="table table-striped table-hover text-center mt-3" id="propTable">
          <thead>
            <th class='tableTitle' colspan="4">Proprietários</th>
          </thead>
          <thead>
            <th>Razão</th>
            <th>Fantasia</th>
            <th>E-mail</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of propList">
              <td>{{item.razao}}</td>
              <td>{{item.fantasia}}</td>
              <td>{{item.email}}</td>
              <button  class="btn bg-gradient-info btn-sm m-1" (click)="openVinculoProp(contentVinculoProp,item);">Adicionar Motorista</button>
              <button class="btn bg-gradient-warning btn-sm m-1"><i class="fas fa-cog"></i></button>
            </tr>
          </tbody>
        </table>
        <hr>
        <table class="table table-striped table-hover text-center mt-3" id="motoTable">
          <thead>
            <th class='tableTitle' colspan="4">Motoristas</th>
          </thead>
          <thead>
            <th>Nome</th>
            <th>Apelido</th>
            <th>Telefone</th>
            <th>proprietario</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of motoList">
              <td>{{item.nome}}</td>
              <td>{{item.apelido}}</td>
              <td>{{item.telefone}}</td>
              <td>{{item.proprietario}}</td>
              <button class="btn bg-gradient-warning btn-sm m-1"><i class="fas fa-cog"></i></button>
            </tr>
          </tbody>
        </table>
        <hr>
        <table class="table table-striped table-hover text-center mt-3" id="veicTable">
          <thead>
            <th class='tableTitle' colspan="4">Veículos</th>
          </thead>
          <thead>
            <th>Tipo</th>
            <th>Placa</th>
            <th>Marca</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of veicList">
              <td *ngIf='item.tipo_veiculo === "0"'>Veículo</td>
              <td *ngIf='item.tipo_veiculo === "1"'>Carreta</td>
              <td>{{item.placa}}</td>
              <td>{{item.marca}}</td>
              <button class="btn bg-gradient-warning btn-sm m-1"><i class="fas fa-cog"></i></button>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</ng-template>

<!-- Modal Cadastro-->
<ng-template #contentPedido>
  <section class="content ml-2 mr-2 mt-2">

    <div class="card">
      <div class="modal-header">
        <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>Novo Pedido</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
          <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
        </div>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="card-body p-3">

        <!-- 1ncode: Tipo de Pedido -->
        <div class="col-md-12 col-sm-12 col-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Tipo de Pedido</span>
            </div>
            <select class="form-control" name="tipo-pedido" (change)="getTipo($event)">
              <option>Selecione</option>
              <option *ngFor="let item of tipo" value={{item.id}}>{{item.id}} - {{item.descricao}}</option>
            </select>
          </div>
        </div>

        <div class="row">

          <!-- 1ncode: Nome da Empresa  -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Empresa</span>
              </div>
              <select class="form-control" name="empresa" (change)="getEmpresa($event)">
                <option value=null>Selecione</option>
                <option *ngFor="let item of empresas" value={{item.id}}>{{item.id}} - {{item.razaosocial}}</option>
              </select>
            </div>
          </div>

          <!-- 1ncode: Forma de Pedido -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Forma Pedido</span>
              </div>
              <select class="form-control" name="forma_pedido" (change)="getForma($event)">
                <option>Selecione</option>
                <option *ngFor="let item of forma" value={{item.id}}>{{item.id}}- {{item.descricao}}</option>
              </select>
            </div>
          </div>

          <!-- 1ncode: Data Registro - Novo Cadastro -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Data Registro</span>
              </div>
              <input type="datetime-local" disabled name="data-registro" class="form-control"
                [(ngModel)]=pedido.dataregistro>
            </div>
          </div>

          <!-- 1ncode: Expressa - Novo Cadastro -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Expressa</span>
              </div>
              <select class="form-control" name="expressa" (change)="getExpressa($event)">
                <option>Selecione</option>
                <option value="sim">Sim</option>
                <option value="nao">Não</option>
              </select>
            </div>
          </div>

          <!-- 1ncode: Valor Mercadoria - Novo Cadastro -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Valor Mercadoria</span>
              </div>
              <input (input)="getValorMercadoria($event)" type="text" name="vlr-mercadoria" class="form-control"
                placeholder="$">
            </div>
          </div>

          <!-- 1ncode: Produto Predominante - Novo Cadastro -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Produto Predominante</span>
              </div>
              <input (input)="getProdutoPred($event)" type="text" name="nome_motorista" class="form-control"
                placeholder="...">
            </div>
          </div>

        </div>
        <hr>

      </div>

      <div class="card-footer p-2">
        <button type="submit" class="btn bg-gradient-success float-right" (click)="insertInfo()">Cadastrar</button>
      </div>

    </div>

  </section>
</ng-template>

<!-- Modal Cadastro Proprietario/Motorista -->
<ng-template #contentPropMot>
  <section class="content ml-2 mr-2 mt-2">

    <div class="card">
      <div class="modal-header">
        <h3 [hidden]="propMotHelper!=='0'" class="card-title"><i class="fas fa-address-card mr-3"></i>Novo Proprietário
        </h3>
        <h3 [hidden]="propMotHelper!=='1'" class="card-title"><i class="fas fa-address-card mr-3"></i>Novo Motorista
        </h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
          <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
        </div>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="card-body p-3">

        <!-- 1ncode: TipoCadastro - Novo PropMot -->
        <div class="col-md-12 col-sm-12 col-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Tipo Cadastro</span>
            </div>
            <select [(ngModel)]=propMotHelper class="form-control" name="tipo-cadastro">
              <option value="0">Proprietário</option>
              <option value="1">Motorista</option>
            </select>
          </div>
        </div>

        <!-- Row Proprietário -->
        <div [hidden]="propMotHelper!=='0'" class="row">

          <!-- 1ncode: CNPJ - Novo Proprietário -->
          <div class="col-md-12 col-sm-12 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">CNPJ / CPF</span>
              </div>
              <select [(ngModel)]=proprietario.tipoDocumento class="form-control" name="tipoDocumento">
                <option value="" disabled>Selecione</option>
                <option value="cpf" selected>CPF</option>
                <option value="cnpj">CNPJ</option>
              </select>
            </div>
          </div>

          <!-- 1ncode: CNPJ - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento === 'cnpj'" >
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">CNPJ</span>
              </div>
              <input [(ngModel)]=proprietario.cnpj type="text" name="cnpj" maxlength="14" class="form-control" placeholder="00000000">
            </div>
          </div>

          <!-- 1ncode: CNPJ - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento === 'cpf' ">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">CPF</span>
              </div>
              <input [(ngModel)]=proprietario.cnpj type="text" name="cnpj" class="form-control" placeholder="00000000">
            </div>
          </div>

          <!-- 1ncode: Razão - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento === 'cnpj' ">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Razão Social</span>
              </div>
              <input [(ngModel)]=proprietario.razao type="text" name="razao" class="form-control" maxlength="100"
                placeholder="Razão Social">
            </div>
          </div>

          <!-- 1ncode: Fantasia - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento === 'cpf' ">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Nome Completo</span>
              </div>
              <input [(ngModel)]=proprietario.fantasia type="text" name="fantasia" class="form-control" maxlength="150"
                placeholder="Nome Fantasia">
            </div>
          </div>

          <!-- 1ncode: Fantasia - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento === 'cnpj' ">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Nome Fantasia</span>
              </div>
              <input [(ngModel)]=proprietario.fantasia type="text" name="fantasia" class="form-control" maxlength="150"
                placeholder="Nome Fantasia">
            </div>
          </div>
          
          <!-- 1ncode: IE - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento === 'cnpj' ">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Inscrição Estadual</span>
              </div>
              <input [(ngModel)]=proprietario.ie type="text" name="ie" class="form-control" maxlength="20"
                placeholder="00000000">
            </div>
          </div>

          <!-- 1ncode: RG - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento === 'cpf' ">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">RG</span>
              </div>
              <input [(ngModel)]=proprietario.rg type="text" name="rg" class="form-control" maxlength="15"
                placeholder="00000000">
            </div>
          </div>

          <!-- 1ncode: Orgão Emiss RG - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento === 'cpf' ">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Orgão Emiss. RG</span>
              </div>
              <input [(ngModel)]=proprietario.orgao_emiss_rg type="text" name="orgao_emiss_rg" class="form-control"
                maxlength="6" placeholder="Orgão">
            </div>
          </div>

          <!-- 1ncode: Endereço - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Endereço</span>
              </div>
              <input [(ngModel)]=proprietario.endereco type="text" name="endereco" class="form-control" maxlength="200"
                placeholder="Rua">
            </div>
          </div>

          <!-- 1ncode: Número - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Número</span>
              </div>
              <input id="numero-proprietario" [(ngModel)]=proprietario.numero type="text" name="numero"
                class="form-control" placeholder="99999">
            </div>
          </div>

          <!-- 1ncode: Complemento - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Complemento</span>
              </div>
              <input [(ngModel)]=proprietario.complemento type="text" name="complemento" class="form-control"
                maxlength="50" placeholder="Complemento">
            </div>
          </div>

          <!-- 1ncode: Bairro - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Bairro</span>
              </div>
              <input [(ngModel)]=proprietario.bairro type="text" name="bairro" class="form-control" maxlength="100"
                placeholder="Bairro">
            </div>
          </div>

          <!-- 1ncode: IBGE Endereço - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">IBGE Endereço</span>
              </div>
              <input id="ibge-proprietario" [(ngModel)]=proprietario.ibge_endereco type="text" maxlength="7" name="ibge_endereco"
                class="form-control" placeholder="IBGE do endereço">
            </div>
          </div>

          <!-- 1ncode: ID Cidade End - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Cidade</span>
              </div>
              <input class="col-3" type="text" (change)="searchCidade()" [(ngModel)]="searchCidadeValue">
              <select [(ngModel)]=proprietario.id_cidade_end class="form-control" name="id_cidade_end">
                <option value=null disabled selected>Selecione</option>
                <option *ngFor="let cidade of filteredCidades" [value]="cidade.id">{{cidade.nome_cidade}}</option>
              </select>
            </div>
          </div>

          <!-- 1ncode: CEP - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">CEP</span>
              </div>
              <input [(ngModel)]=proprietario.cep type="text" name="cep" class="form-control" maxlength="10"
                placeholder="CEP">
            </div>
          </div>

          <!-- 1ncode: Email - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento" >
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Email</span>
              </div>
              <input [(ngModel)]=proprietario.email type="email" name="email" class="form-control" maxlength="100"
                placeholder="Email">
            </div>
          </div>



          <!-- 1ncode: Telefone - Novo Proprietário -->
          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Telefone</span>
              </div>
              <input id="telefone-proprietario" [(ngModel)]=proprietario.telefone type="text" maxlength="13" name="telefone"
                class="form-control" placeholder="(99) 99999999">
            </div>
          </div>


        </div>

        <!-- Row Motorista -->
        <div [hidden]="propMotHelper!=='1'" class="row">

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Nome</span>
              </div>
              <input [(ngModel)]=motorista.nome type="text" name="nome" class="form-control" maxlength="45"
                placeholder="Nome">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">CPF</span>
              </div>
              <input [(ngModel)]=motorista.cpf type="text" name="cpf" class="form-control" maxlength="11"
                placeholder="CPF">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Data Nascimento</span>
              </div>
              <input [(ngModel)]=motorista.data_nascimento type="date" name="data-nascimento" class="form-control"
                placeholder="00/00/0000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">IBGE Cidade Nascimento</span>
              </div>
              <input id="ibge-nasc-motorista" [(ngModel)]=motorista.ibge_cidade_nascimento type="text"
                name="ibge-cidade-nascimento" class="form-control" placeholder="00000000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Cidade Nasc.</span>
              </div>
              <input class="col-3" type="text" (change)="searchCidade()" [(ngModel)]="searchCidadeValue">
              <select [(ngModel)]=motorista.id_cidade_nasc class="form-control" name="id-cidade-nasc">
                <option value=null disabled selected>Selecione</option>
                <option *ngFor="let cidade of filteredCidades" [value]="cidade.id">{{cidade.nome_cidade}}</option>
              </select>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Endereço</span>
              </div>
              <input [(ngModel)]=motorista.endereco type="text" name="endereco" class="form-control" maxlength="100"
                placeholder="Rua">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Bairro</span>
              </div>
              <input [(ngModel)]=motorista.bairro type="text" name="bairro" class="form-control" maxlength="45"
                placeholder="Bairro">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">CEP</span>
              </div>
              <input [(ngModel)]=motorista.cep type="text" name="cep" class="form-control" maxlength="10"
                placeholder="000000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Complemento</span>
              </div>
              <input [(ngModel)]=motorista.complemento type="text" name="complemento" class="form-control"
                maxlength="45" placeholder="Complemento">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Número</span>
              </div>
              <input [(ngModel)]=motorista.numero type="text" name="numero" class="form-control" maxlength="50"
                placeholder="Número">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">IBGE Endereço</span>
              </div>
              <input id="ibge-end-motorista" [(ngModel)]=motorista.ibge_endereco type="text" name="ibge_endereco"
                class="form-control" placeholder="IBGE do endereço">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Cidade</span>
              </div>
              <input class="col-3" type="text" (change)="searchCidade()" [(ngModel)]="searchCidadeValue">
              <select [(ngModel)]=motorista.id_cidade_end class="form-control" name="id-cidade-end">
                <option value=null disabled selected>Selecione</option>
                <option *ngFor="let cidade of filteredCidades" [value]="cidade.id">{{cidade.nome_cidade}}</option>
              </select>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Apelido</span>
              </div>
              <input [(ngModel)]=motorista.apelido type="text" name="apelido" class="form-control" maxlength="45"
                placeholder="Apelido">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Sexo</span>
              </div>
              <select [(ngModel)]=motorista.sexo class="form-control" name="sexo" placeholder='Selecione'>
                <option value="" disabled selected>Selecione</option>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </select>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">RG</span>
              </div>
              <input [(ngModel)]=motorista.rg type="text" name="rg" class="form-control" maxlength="15"
                placeholder="00000000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Orgão Emiss. RG</span>
              </div>
              <input [(ngModel)]=motorista.orgao_emissor_rg type="text" name="orgao-emissor-rg" class="form-control"
                maxlength="6" placeholder="Orgão">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12" *ngIf="proprietario.tipoDocumento === 'cpf' ">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Data Emiss. RG</span>
              </div>
              <input [(ngModel)]=motorista.data_emiss_rg type="date" name="data-emiss-rg" class="form-control"
                placeholder="00/00/0000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Num. Form. CNH</span>
              </div>
              <input id="num-form-cnh-motorista" [(ngModel)]=motorista.num_form_cnh type="text" name="num-form-cnh"
                class="form-control" placeholder="0000000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Num. Reg. CNH</span>
              </div>
              <input id="num-reg-cnh-motorista" [(ngModel)]=motorista.num_reg_cnh type="text" name="num-reg-cnh"
                class="form-control" placeholder="0000000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Num. Seg. CNH</span>
              </div>
              <input [(ngModel)]=motorista.num_segur_cnh type="text" name="num-seg-cnh" class="form-control"
                maxlength="11" placeholder="0000000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Num. Renach. CNH</span>
              </div>
              <input [(ngModel)]=motorista.num_renach_cnh type="text" name="num-renach-cnh" class="form-control"
                maxlength="15" placeholder="0000000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">UF Emiss. CNH</span>
              </div>
              <input [(ngModel)]=motorista.uf_emiss_cnh type="text" name="uf-emiss-cnh" class="form-control"
                maxlength="2" placeholder="UF">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Data Emiss. CNH</span>
              </div>
              <input [(ngModel)]=motorista.data_emiss_cnh type="date" name="data-emiss-cnh" class="form-control"
                placeholder="00/00/0000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Data Venc. CNH</span>
              </div>
              <input [(ngModel)]=motorista.data_venc_cnh type="date" name="data-venc-cnh" class="form-control"
                placeholder="00/00/0000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Categoria CNH</span>
              </div>
              <input [(ngModel)]=motorista.categoria_cnh type="text" name="categoria-cnh" class="form-control"
                maxlength="2" placeholder="Categoria">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Data Prim. Emiss. CNH</span>
              </div>
              <input [(ngModel)]=motorista.dt_prim_emiss_cnh type="date" name="dt-prim-emiss-cnh" class="form-control"
                placeholder="00/00/0000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Possui MOPP</span>
              </div>
              <select [(ngModel)]=motorista.possui_mopp class="form-control" name="mopp">
                <option value="" disabled selected>Selecione</option>
                <option value="s">Sim</option>
                <option value="n">Não</option>
              </select>
            </div>
          </div>

          <div [hidden]="motorista.possui_mopp!=='s'" class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Data Venc. MOPP</span>
              </div>
              <input [(ngModel)]=motorista.dt_venc_mopp type="date" name="dt-venc-mopp" class="form-control"
                placeholder="00/00/0000">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Nome da Mãe</span>
              </div>
              <input [(ngModel)]=motorista.nome_mae type="text" name="nome-mae" class="form-control" maxlength="45"
                placeholder="Nome">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Telefone</span>
              </div>
              <input id="telefone-motorista" [(ngModel)]=motorista.telefone type="text" name="telefone"
                class="form-control" placeholder="(99) 99999999">
            </div>
          </div>

          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Celular</span>
              </div>
              <input id="celular-motorista" [(ngModel)]=motorista.celular type="text" name="celular"
                class="form-control" placeholder="(99) 99999999">
            </div>
          </div>

        </div>
        <hr>

      </div>

      <div class="card-footer p-2">
        <button [hidden]="propMotHelper!=='0'" type="submit" class="btn bg-gradient-success float-right"
          (click)="insertProprietario()">Cadastrar</button>
      </div>
      <div class="card-footer p-2">
        <button [hidden]="propMotHelper!=='1'" type="submit" class="btn bg-gradient-success float-right"
          (click)="insertMotorista()">Cadastrar</button>
      </div>

    </div>

  </section>
</ng-template>

<!-- Modal Cadastro Veiculo -->
<ng-template #contentVeiculo>
  <section class="content ml-2 mr-2 mt-2">

    <div class="card">
      <div class="modal-header">
        <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>Novo Veículo</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
          <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
        </div>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="card-body p-3">
        <!-- Row Veiculo -->
        <div class="row">
          <!-- 1ncode: Tipo Veiculo - Novo Veiculo -->
          <div class="col-md-12 col-sm-12 col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">Tipo Veículo</span>
              </div>
              <select [(ngModel)]=veiculo.tipo_veiculo class="form-control" name="tipo-veiculo">
                <option value="0">Tração</option>
                <option value="1">Reboque</option>
              </select>
            </div>
          </div>

          <!-- 1ncode: Placa - Novo Veiculo -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Placa</span>
              </div>
              <input [(ngModel)]=veiculo.placa type="text" name="placa" class="form-control" maxlength="7"
                placeholder="Digite placa">
            </div>
          </div>

          <!-- 1ncode: Chassi - Novo Veiculo -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Chassi</span>
              </div>
              <input [(ngModel)]=veiculo.chassi type="text" style="text-transform: uppercase;" name="chassi" class="form-control" maxlength="17"
                placeholder="Digite chassi">
            </div>
          </div>

          <!-- 1ncode: Cor - Novo Veiculo -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Cor</span>
              </div>
              <input [(ngModel)]=veiculo.cor type="text" name="cor" class="form-control" maxlength="45"
                placeholder="Digite cor">
            </div>
          </div>

          <!-- 1ncode: Renavan - Novo Veiculo -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Renavan</span>
              </div>
              <input [(ngModel)]=veiculo.renavan type="text" name="renavan" class="form-control" maxlength="11"
                placeholder="Apenas Números">
            </div>
          </div>

          <!-- 1ncode: Ano Fabricacao - Novo Veiculo -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Ano Fabricação</span>
              </div>
              <input id="ano-fab-veiculo" [(ngModel)]=veiculo.ano_fabricacao type="text" name="ano-fabricacao"
                class="form-control" placeholder="0000">
            </div>
          </div>

          <!-- 1ncode: Ano Modelo - Novo Veiculo -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Ano Modelo</span>
              </div>
              <input id="ano-mod-veiculo" [(ngModel)]=veiculo.ano_modelo type="text" name="modelo" class="form-control"
                placeholder="Digite ano modelo">
            </div>
          </div>

          <!-- 1ncode: Marca - Novo Veiculo -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Marca</span>
              </div>
              <input [(ngModel)]=veiculo.marca type="text" name="marca" class="form-control" maxlength="45"
                placeholder="Digite marca">
            </div>
          </div>

          <!-- 1ncode: Modelo - Novo Veiculo -->
          <div class="col-md-6 col-sm-6 col-12">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">Modelo</span>
              </div>
              <input [(ngModel)]=veiculo.modelo type="text" name="modelo" class="form-control" maxlength="45"
                placeholder="Digite modelo">
            </div>
          </div>

        </div>
        <hr>
      </div>

      <div class="card-footer p-2">
        <button type="submit" class="btn bg-gradient-success float-right" (click)="insertVeiculo()">Cadastrar</button>
      </div>
    </div>
  </section>
</ng-template>

<!-- Modal Cadastro Veiculo -->
<ng-template #contentVinculoProp>
  <section class="content ml-2 mr-2 mt-2">

    <div class="card">
      <div class="modal-header">
        <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>Motorista</h3>
        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
          <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
        </div>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="card-body p-3">
        <!-- Row Veiculo -->
        <div class="row">
          <!-- 1ncode: Tipo Veiculo - Novo Veiculo -->
          <div class="col-md-12 col-sm-12 col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">Motorista</span>
              </div>
              <select [(ngModel)]=proprietario.motorista_id class="form-control" name="tipo-veiculo">
                <option>Selecione</option>
                <option *ngFor="let item of motoList" value={{item.id}}>{{item.cpf}} - {{item.nome}}</option>
              </select>
            </div>
          </div>
        </div>
        <hr>
      </div>

      <div class="card-footer p-2">
        <button type="submit" class="btn bg-gradient-success float-right" (click)="updateProprietario()">Adicionar</button>
      </div>
    </div>
  </section>
</ng-template>