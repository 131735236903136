
  <app-sidebar></app-sidebar>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper" style="overflow-x: hidden;">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Dashboard</h1>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-gradient-info">
              <div class="inner">
                <h3></h3>

                <p>Cadastros Realizados</p>
              </div>
              <div class="icon">
                <i class="fas fa-address-card"></i>
              </div>
              <a href="cadastro" class="small-box-footer">Visualizar Cadastros</a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-gradient-success">
              <div class="inner">
                <h3></h3>

                <p>Cadastros <b>Aprovados</b></p>
              </div>
              <div class="icon">
                <i class="far fa-check-circle"></i>
              </div>
              <a href="cadastro" class="small-box-footer">Visualizar Cadastros</a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-gradient-danger">
              <div class="inner">
                <h3></h3>

                <p>Cadastros <b>Reprovados</b></p>
              </div>
              <div class="icon">
                <i class="far fa-times-circle"></i>
              </div>
              <a href="cadastro" class="small-box-footer">Visualizar Cadastros</a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-gradient-warning">
              <div class="inner">
                <h3></h3>

                <p>Consultas Realizadas</p>
              </div>
              <div class="icon">
                <i class="fas fa-search"></i>
              </div>
              <a href="consulta" class="small-box-footer">Visualizar Consultas</a>
            </div>
          </div>
          <!-- ./col -->
        </div>
        <!-- /.row -->
        <!-- Main row -->
        <div class="row">
          <!-- Left col -->
          <section class="col-lg-6 connectedSortable">

            <!-- Custom tabs (Charts with tabs)-->
            <div class="card">
              <div class="card-header">
                <h3 class="card-title" style="padding-top: 5px;">
                  <i class="far fa-calendar-check mr-2"></i> <b>Gráfico de Aprovação:</b>
                </h3>
                <div class="card-tools">
                  <ul class="nav nav-pills ml-auto">
                    <li class="nav-item">
                      <a class="nav-link btn-sm active" href="#chart-motoristas" data-toggle="tab">Motoristas</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link btn-sm" href="#chart-cavalos" data-toggle="tab">Cavalos</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link btn-sm" href="#chart-carretas" data-toggle="tab">Carretas</a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /.card-header -->

              <div class="card-body pl-2 pr-2 pt-1 pb-1">
                <div class="tab-content">

                  <!-- Charts.js - Motoristas -->
                  <div class="chart tab-pane active" id="chart-motoristas" style="position: relative; height: 300px;">
                      <canvas id="chart-motoristas-canvas" height="300" style="height: 300px;"></canvas>                         
                   </div>

                  <!-- Charts.js - Cavalos -->
                  <div class="chart tab-pane" id="chart-cavalos" style="position: relative; height: 300px;">
                    <canvas id="chart-cavalos-canvas" height="300" style="height: 300px;"></canvas>                         
                  </div>

                  <!-- Charts.js - Carretas -->
                  <div class="chart tab-pane" id="chart-carretas" style="position: relative; height: 300px;">
                    <canvas id="chart-carretas-canvas" height="300" style="height: 300px;"></canvas>                         
                  </div>

                </div>
              </div>
              <!-- /.card-body -->

            </div>
            <!-- /.card -->

            <!-- Default box -->
            <div class="card">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-address-card mr-2"></i> <b>Últimas Solicitações</b> </h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                  <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
                </div>
              </div>
              <div class="card-body p-0">

                <table class="table table-striped table-hover text-center table-responsive-xl">
                  <thead>
                    <tr>
                      <th>Protocolo</th>
                      <th>Data de Solicitação</th>
                      <th>Motorista</th>
                      <th>Cavalo</th>
                      <th>Carreta</th>
                      <th>Tipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <!-- <td><?php echo $rows['protocolo']; ?></td>
                      <td><?php echo substr($rows['data_cadastro'], 0, 16); ?></td>
                      <td><?php echo $rows['status_motorista']; ?><?php echo $rows['validade_motorista']; ?></span></td>
                      <td><?php echo $rows['status_cavalo']; ?><?php echo $rows['validade_cavalo']; ?></span></td>
                      <td><?php echo $rows['status_carreta']; ?><?php echo $rows['validade_carreta']; ?></span></td>
                      <td><?php echo $rows['tipo_solicitacao']; ?></td> -->
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>
            <!-- /.card -->

          </section>
          <!-- /.Left col -->
          <!-- right col (We are only adding the ID to make the widgets sortable)-->
          <section class="col-lg-6 connectedSortable">

            <!-- Widget: user widget style 1 -->
            <div class="card card-widget widget-user">
              <!-- Add the bg color to the header using any of the bg-* classes -->
              <div class="widget-user-header text-white" style="background: url('../all/dist/img/photo1.png') center center; background-size: cover;">
                <h3 class="widget-user-username text-right">{{user.nome}}</h3>
                <h5 class="widget-user-desc text-right">{{user.email}}</h5>
              </div>
              <div class="widget-user-image">
                <img class="user-img-lg" src="../all/dist/img/avatar>">
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header">10</h5>
                      <span class="description-text"><b>Cadastros Solicitados</b></span>
                    </div>
                    <!-- /.description-block -->
                  </div>
                  <!-- /.col -->
                  <div class="col-sm-4 border-right">
                    <div class="description-block">
                      <h5 class="description-header"></h5>
                      <span class="description-text"></span>
                    </div>
                    <!-- /.description-block -->
                  </div>
                  <!-- /.col -->
                  <div class="col-sm-4">
                    <div class="description-block">
                      <h5 class="description-header">15</h5>
                      <span class="description-text"><b>Consultas Solicitadas</b></span>
                    </div>
                    <!-- /.description-block -->
                  </div>
                  <!-- /.col -->
                </div>
                <!-- /.row -->
              </div>
            </div>
            <!-- /.widget-user -->

          </section>
          <!-- right col -->
        </div>
        <!-- /.row (main row) -->
      </div><!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
  <app-footer></app-footer>
  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->
<!-- ./wrapper -->